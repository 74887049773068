import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { BenDataProps } from "../../Helper/BenInterface";
interface SubscriberData {
  subscriber: BenDataProps;
}
interface BeneficiaryData {
  beneficiary: BenDataProps[];
}
interface MyComponentProps {
  children: ReactElement;
}

const PrivateRoute: React.FC<MyComponentProps> = ({ children }) => {
  const subscriberData = useSelector(
    (state: SubscriberData) => state.subscriber
  );
  const userData = useSelector((state: any) => state.user);
  const beneficiaryData = useSelector(
    (state: BeneficiaryData) => state.beneficiary
  );

  // console.log('beneficiaryData:', beneficiaryData)
  // const [hasIncompleteBeneficiary, setHasIncompleteBeneficiary] = useState(false);
  // useEffect(() => {
  //     let hasIncomplete = false;
  //     if (beneficiaryData){
  //         for (let i = 0; i < beneficiaryData.length; i++) {
  //             if (beneficiaryData[i].personalInfo?.mobile?.number.toString() === "" || beneficiaryData[i].address.length === 0) {
  //                 hasIncomplete = true;
  //                 break;
  //             }
  //         }
  //         setHasIncompleteBeneficiary(hasIncomplete);
  //     }
  // }, [beneficiaryData]);

  if (!Object.keys(subscriberData).length) {
    return <Navigate to={"/"} />;
  }

  // if ((beneficiaryData.length < 2) && (subscriberData?.personalInfo?.email?.trim().split("@")[1] !== "persistent.com")) {
  //     return <Navigate to={"/addBeneficiary"} />;
  //   }
  // if ((beneficiaryData.length < 2) && (getUserData?.addBeneficiaryAllowed=== true)) {
  //     return <Navigate to={"/addBeneficiary"} />;
  // }
  // console.log('userDat_check_PrivateRoutea:', userData);
  // console.log("check_PrivateRoute",userData?.addBeneficiaryAllowed);

  // if ((beneficiaryData.length < 2) && (userData?.addBeneficiaryAllowed === true)) {
  //     return <Navigate to={"/addBeneficiary"} />;
  // };

  // if (
  //   userData?.numberOfBenAllowed === 1 &&
  //   userData?.numberOfBenAllowed !== undefined
  // ) {
  //   alert("check")
  //   return <Navigate to={"/dashboard"} />;
  // }

    if (userData.numberOfBenAllowed === 0) {
        if ((beneficiaryData?.length < 2) && (userData?.addBeneficiaryAllowed === true)) {
        return <Navigate to={"/addBeneficiaries"} />;
        }
    } 
    // else {
    //     if ((beneficiaryData?.length < userData?.numberOfBenAllowed) && (userData?.addBeneficiaryAllowed === true)) {
    //         console.log("b2c condition hit private route")
    //         return <Navigate to={"/addBeneficiaries"} />;
    //     }
    // }
    // if (hasIncompleteBeneficiary){
    //     return <Navigate to={"/addBenMinDetails"} />;  
    // }//extra protection
    return children
}

export default PrivateRoute;
