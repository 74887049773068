import Navstyle from "./Navbar.module.css";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
// import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "../../Pages/Landing/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { LogOutAction } from "./LoginAction/LogOutAction";
import { BenDataProps } from "../../Helper/BenInterface";

interface SubscriberData {
  subscriber: BenDataProps;
}
const Navbar: React.FC = () => {
  let SasToken = localStorage.getItem("sasToken") || "";
  const location = useLocation();
  const [loginModal, showLoginModal] = useState(false);
  //eslint-disable-next-line
  const { isOpen, onOpen, onClose } = useDisclosure();
  const subscriberData = useSelector(
    (state: SubscriberData) => state.subscriber
  );
  const benificiaryData = useSelector((state: any) => state.beneficiary);

  const [isLargerThan500] = useMediaQuery('(max-width: 500px)')

  const loginData = benificiaryData.filter((el: any) => {
    return subscriberData?._id === el._id;
  });
  console.log("location:", location.pathname === "/");
  const dispatch = useDispatch();

  const openModal = () => {
    showLoginModal(true);
  };

  const closeModal = () => {
    showLoginModal(false);
  };

  const navigate = useNavigate();
  const logoutHandler = async () => {
    await LogOutAction(subscriberData.userId, dispatch);
  };

  const handleLogoClick = () => {
    if (!benificiaryData.length) {
      navigate("/");
    } else {
      navigate("/dashboard");
    }
  };
  const handleProfileClick = () => {
    if (subscriberData) return navigate(`/profile/view/${subscriberData._id}`);
  };

  // Orders page redirection
  const handleOrdersClick = () => {
    return navigate("/orders");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <Box className={Navstyle.navbar}>
        <Box height={"100%"} display={"flex"} alignItems={"center"}>
          <Flex
            w={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <HStack className={Navstyle.navAlign}>
              <Box>
                <Image
                  cursor={"pointer"}
                  onClick={handleLogoClick}
                  width={{ base: "125", md: "150" }}
                  height={{ base: "35", md: "57" }}
                  src="/Images/logo.png"
                  alt="logo"
                />
              </Box>

            </HStack>
            {benificiaryData.length === 0 && isLargerThan500 && (
              // <IconButton
              //   size={"lg"}
              //   icon={
              //     isOpen ? (
              //       <CloseIcon fontSize={"1.3rem"} />
              //     ) : (
              //       <HamburgerIcon fontSize={"1.6rem"} />
              //     )
              //   }
              //   aria-label={"Open Menu"}

              //   onClick={isOpen ? onClose : onOpen}
              // />
              location.pathname === "/" && <button onClick={openModal} className={Navstyle.button_Style}>
                Login
              </button>
            )}



            {isLargerThan500 ? <></> : benificiaryData.length === 0 ? (<HStack spacing={"2.5rem"} className={Navstyle.navAlign}>
              <Text
                className={
                  location.pathname === "/"
                    ? Navstyle.link_Style
                    : Navstyle.link_Style_opacity
                }
                onClick={() => navigate("/")}
              >
                Home
              </Text>
              {/* <Text
                className={
                  location.pathname === "/investors"
                    ? Navstyle.link_Style
                    : Navstyle.link_Style_opacity
                }
                onClick={() => navigate("/investors")}
              >
                Investors
              </Text> */}
            </HStack>) : null}

            {isLargerThan500 ? <></> : benificiaryData.length === 0 ? (<HStack className={Navstyle.leftNavAlign}>

              <Text onClick={() => navigate("/about")} className={location.pathname === "/about"
                ? Navstyle.link_Style
                : Navstyle.link_Style_opacity}>About</Text>
              <Text onClick={() => navigate("/careers")} className={location.pathname === "/careers"
                ? Navstyle.link_Style
                : Navstyle.link_Style_opacity}>Careers</Text>
              {
                location.pathname === "/" && <button onClick={openModal} className={Navstyle.button_Style}>
                  Login
                </button>

              }

            </HStack>) :
              <HStack className={Navstyle.navAlign}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                  >
                    <Avatar
                      style={{ marginLeft: "10px" }}
                      size={"sm"}
                      src={
                        loginData
                          ? loginData[0]?.personalInfo?.image + "?" + SasToken
                          : ""
                      }
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                    <MenuItem onClick={handleOrdersClick}>Orders</MenuItem>
                    <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                  </MenuList>
                </Menu>
              </HStack>}

            {!!isLargerThan500 && benificiaryData.length > 0 ? <HStack className={Navstyle.navAlign}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    style={{ marginLeft: "10px" }}
                    size={"sm"}
                    src={
                      loginData
                        ? loginData[0]?.personalInfo + "?" + SasToken
                        : ""
                    }
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                  <MenuItem onClick={handleOrdersClick}>Orders</MenuItem>
                  <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </HStack> : null

            }
          </Flex>
        </Box>

        {/* {benificiaryData.length === 0 && isOpen ? (
          <Box  pl={"1rem"}   backgroundColor={"#ffff"} pb={10} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={6}>
              <Link to="/" className={Navstyle.link_Style}>
                Home
              </Link>
              <Link className={Navstyle.link_Style} to="/investor">
             investors
              </Link>
              { location.pathname ==="/"&&<Link className={Navstyle.link_Style} to=""onClick={openModal} >
                Login
              </Link>}
             
            </Stack>
          </Box>
        ) : null} */}

      </Box>

      {/* <hr className={Navstyle.hr} /> */}
      <Login loginModal={loginModal} onClose={closeModal} />
    </>
  );
};

export default Navbar;
